<template>
  <section>
    <div class="flex-layout">
      <div class="logo">
        <h3>largerthan <span class="italic">i</span></h3>
      </div>

      <div class="team">
        <h5>Our team</h5>
        <router-link to="/about-us">About us</router-link>
        <router-link to="/about-us">Team</router-link>
        <router-link to="/what-we-do">What we do</router-link>
        <router-link to="/contact-us">Contact </router-link>
      </div>

      <div class="team">
        <h5>More</h5>
        <router-link to="/media">Projects</router-link>
        <router-link to="/media">Events</router-link>
        <router-link to="/donation">Donate</router-link>
      </div>

      <div class="team">
        <h5>Connect</h5>
        <router-link to="/">Facebook</router-link>
        <router-link to="/">Instagram</router-link>
        <router-link to="/">Twitter</router-link>
        <router-link to="/">LinkedIn </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MainFooter',
};
</script>

<style scoped>
section {
  background: #000;
  color: #fff;
}

a {
  text-decoration: none;
  color: #fff;
  display: block;
  margin: 10px 0;
  opacity: 0.78;
}

h5 {
  font-size: 16px;
}

.italic {
  font-style: italic;
}

@media (max-width: 600px) {
  .flex-layout {
    align-items: start;
  }
}
</style>
