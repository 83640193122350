<template>
  <div class="hidden">
    <vs-navbar
      class="navbar"
      :class="{ applybackground: applyBackground }"
      shadow
      square
      center-collapsed
      v-model="active"
    >
      <template #left>
        <vs-button
          class="toggle-icon"
          @click="activeSidebar = !activeSidebar"
          flat
          icon
        >
          <i class="bx bx-menu"></i>
        </vs-button>

        <router-link to="/"
          >largerthan<span class="italic">1</span></router-link
        >
      </template>

      <template class="right" #right>
        <vs-navbar-item :active="active == 'guide'" id="guide">
          <router-link to="/">Home</router-link>
        </vs-navbar-item>
        <vs-navbar-item :active="active == 'docs'" id="docs">
          <router-link to="/about-us">About us</router-link>
        </vs-navbar-item>
        <vs-navbar-item :active="active == 'components'" id="components">
          <router-link to="/what-we-do">What We Do</router-link>
        </vs-navbar-item>
        <vs-navbar-item :active="active == 'license'" id="license">
          <router-link to="/media">Media</router-link>
        </vs-navbar-item>
        <vs-navbar-item :active="active == 'license'" id="license">
          <router-link to="/contact-us">Contact</router-link>
        </vs-navbar-item>
        <vs-navbar-item :active="active == 'license'" id="license">
          <Button
            :text="'Donate'"
            :background="'#1D2130'"
            :color="'#fff'"
            @click.native="redirect"
          />
        </vs-navbar-item>
      </template>
    </vs-navbar>

    <!-- sidebar -->
    <vs-sidebar absolute v-model="active" :open.sync="activeSidebar">
      <template class="logo-mobile" #logo>
        <!-- ...img logo -->
        <router-link to="/"
          >largerthan<span class="italic">1</span></router-link
        >
      </template>
      <vs-sidebar-item id="home">
        <span class="mobile-link" @click="redirectMobile('/')">Home</span>
      </vs-sidebar-item>

      <vs-sidebar-item id="about">
        <span class="mobile-link" @click="redirectMobile('/about-us')"
          >About Us</span
        >
      </vs-sidebar-item>

      <vs-sidebar-item id="whatwedo">
        <span class="mobile-link" @click="redirectMobile('/what-we-do')"
          >What We Do</span
        >
      </vs-sidebar-item>

      <vs-sidebar-item id="media">
        <span class="mobile-link" @click="redirectMobile('/media')">Media</span>
      </vs-sidebar-item>

      <vs-sidebar-item id="contact">
        <span class="mobile-link" @click="redirectMobile('/contact-us')"
          >Contact</span
        >
      </vs-sidebar-item>

      <vs-sidebar-item id="donation">
        <span class="mobile-link" @click="redirectMobile('/donation')"
          >Donation</span
        >
      </vs-sidebar-item>
    </vs-sidebar>

    <div class="mobile">
      <router-link to="/">largerthan<span class="italic">1</span></router-link>
      <span @click="activeSidebar = !activeSidebar" flat icon>
        <i class="fa fa-bars"></i>
      </span>
    </div>
  </div>
</template>

<script>
import Button from './Button.vue';
export default {
  name: 'Navbar',

  components: { Button },
  data: () => ({
    active: 'home',
    activeSidebar: false,
  }),

  methods: {
    redirect() {
      this.$router.push('/donation');
    },

    redirectMobile(url) {
      this.activeSidebar = false;
      this.$router.push(url);
    },
  },

  computed: {
    applyBackground() {
      return ['/media', '/contact-us', '/donation'].includes(this.$route.path);
    },
  },
};
</script>

<style scoped>
.navbar {
  box-shadow: none !important;
  padding: 0 3rem;
  position: relative;
}

a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #525560;
  text-decoration: none;
}

.italic {
  color: #f2c94c;
  font-style: italic;
}

.toggle-icon {
  display: none;
}

.applybackground {
  background: #fcedc6;
}

.mobile {
  display: none;
}

@media (max-width: 600px) {
  .navbar {
    display: none;
  }

  .mobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1rem 2rem;
    box-sizing: border-box;
    color: #000;
  }

  .mobile-link {
    color: #000;
    display: block;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
  }

  .logo-mobile a {
    font-size: 18px;
    text-align: center;
  }
}
</style>
