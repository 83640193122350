<template>
  <section>
    <div class="flex-layout">
      <div class="image">
        <img src="../../../assets/pic4.png" alt="" srcset="" />
      </div>

      <div class="content">
        <h1 class="heading">How you can contribute to caring for our kids</h1>

        <p class="content-p">
          LTIDF has been on its toes with several interventions from awareness
          creation to outreaches, health and medical interventions, and Parents
          Support programs You can support these outreaches and interventions
          through your prayers, volunteering, financial/material and social
          support. We believe first and above all in divine intervention, so
          your prayers for us is key. Materially speaking, We need buses for
          transportation of the indigent special children from their communities
          to the city school, so they can be schooled, as there are no special
          schools in the communities. We need adequate trainings for our very
          dedicated intervention staff We need parent empowerment support so
          they can have some means of income to bear the huge burden if caring
          for the children. We also need support for our outreaches to
          communities to locate and identify these children in order to give
          needed support. Our scope is expanding yearly. We are reaching
          communities in some neighboring states such as Edo and Rivers States.
          We also require support to give adequate and accessible education to
          the children in the inclusive school. Such as A well equipped sensory
          room, A well equipped vocational center A better equipped play ground
          More teaching aids and classroom furniture.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HowToContribute',
};
</script>
