<template>
  <section>
    <div class="app-container">
      <div class="images">
        <div class="container" v-for="(image, i) in images" :key="i">
          <img class="image" :src="image.photo" @click="index = i" />
          <h3>{{ image.caption }}</h3>
        </div>
      </div>
      <vue-gallery-slideshow
        :images="photos"
        :index="index"
        @close="index = null"
      ></vue-gallery-slideshow>
    </div>
  </section>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
export default {
  name: 'Gallery',
  components: {
    VueGallerySlideshow,
  },

  props: {
    images: Array,
  },

  data() {
    return {
      index: null,

      photos: this.images.map((image) => image.photo),
    };
  },
};
</script>

<style scoped>
img {
  width: 100%;
  display: block;
}

.images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
</style>
