<template>
  <section class="colored-section">
    <div class="heading-dash">
      <span class="side-dash"></span>
      <h5>DONATE</h5>
    </div>

    <div class="flex-layout">
      <div class="content">
        <h1 class="heading">Making a donation for our children.</h1>
        <p class="content-p">
          When you donate, you’re supporting effective care to children with
          special needs—an investment in the leaders of tomorrow.
        </p>

        <Button
          :text="'Donate now'"
          @click.native="active = true"
          :color="'#000'"
          :background="'#F2C94C'"
        />
      </div>

      <div class="image">
        <img
          src="../../../assets/katt-yukawa-K0E6E0a0R3A-unsplash.jpg"
          alt=""
        />
      </div>
    </div>

    <vs-dialog width="300px" not-center v-model="active">
      <template #header>
        <h4 class="not-margin">Enter Detail Here:-</h4>
      </template>

      <div class="con-content">
        <input type="text" placeholder="Enter First Name" v-model="firstName" />

        <input type="text" placeholder="Enter Last Name" v-model="lastName" />

        <input type="text" placeholder="Enter Email" v-model="email" />

        <input type="number" placeholder="Enter Amount" v-model="amount" />
      </div>

      <template #footer>
        <div class="con-footer">
          <Button
            :text="`Initiate Payment`"
            :background="`#F2C94C`"
            :color="`#000000`"
            @click.native="makePayment"
          />
        </div>
      </template>
    </vs-dialog>
  </section>
</template>

<script>
import Button from '../../../components/Button.vue';
export default {
  name: 'Header',
  components: { Button },

  data: () => ({
    email: '',
    amount: null,
    active: false,
    firstName: '',
    lastName: '',
  }),

  methods: {
    makePayment() {
      this.$launchFlutterwave({
        tx_ref: Date.now(),
        amount: this.amount,
        currency: 'NGN',
        payment_options: 'card',
        customer: {
          email: this.email,
          phone_number: this.phone,
          name: `${this.firstName} ${this.lastName}`,
        },
        callback: function ({ status }) {
          if (status === 'successful') {
            this.active = false;
          }
        },
        customizations: {
          title: 'Larger Than I Developemental Foundation',
          description: 'Donation',
          logo: 'LargerthanI',
        },
      });
    },
  },
};
</script>

<style scoped>
.image {
  height: 100%;
}

.content {
  padding-left: 85px;
}

input {
  display: block;
  width: 100%;
  border-radius: 4px;
  padding: 1rem;
  box-sizing: border-box;
  background: 0% 0% / 1rem rgba(0, 0, 0, 0.05);
  border: none;
  margin-bottom: 15px;
}

.con-footer {
  display: flex;
  justify-content: center;
}
@media (max-width: 600px) {
  .content {
    padding-left: 0;
  }
}
</style>
