<template>
  <section>
    <h2 class="heading">Meet our team</h2>
    <p class="content-p">
      Meet our prestigious team who made the vision a reality
    </p>

    <div class="flex-layout">
      <div v-for="(p, i) in profiles" :key="i" class="member">
        <img @click="showMember(p)" :src="p.image" alt="member" />

        <h3>{{ p.name }}</h3>
        <p>{{ p.role }}</p>
      </div>
    </div>

    <vs-dialog width="550px" not-center v-model="active">
      <div class="con-content">
        <img :src="current.image" alt="member" />
        <h4 class="not-margin">{{ current.name }}</h4>

        <p>
          {{ current.bio }}
        </p>
      </div>
    </vs-dialog>
  </section>
</template>

<script>
import profiles from './profiles';
export default {
  name: 'Team',
  data: () => ({
    profiles,
    active: false,
    current: {},
  }),

  methods: {
    showMember(member) {
      if (member.bio) {
        this.current = member;
        this.active = true;
      }
    },
  },
};
</script>

<style scoped>
section {
  position: relative;
  top: 250px;
  text-align: center;
}

.flex-layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

img {
  width: 100%;
  height: 320px;
  width: 296px;
  border-radius: 8px;
}

.content-p {
  color: #525560;
  line-height: 25.6px;
  width: 50%;
  margin: 1rem auto;
  opacity: 0.6;
  font-size: 20px;
}

img:hover {
  cursor: pointer;
}

.con-content {
  text-align: center;
}

.con-content img {
  object-fit: cover;
}

@media (max-width: 600px) {
  .flex-layout {
    grid-template-columns: 1fr;
  }
}
</style>
