<template>
  <div>
    <Header />
    <KnowUs />
    <WhatWeDo />
    <Projects :makeGrey="true" />
    <Donation />
  </div>
</template>

<script>
import Header from './sections/Header.vue';
import KnowUs from './sections/KnowUs.vue';
import WhatWeDo from './sections/WhatWeDo.vue';
import Projects from './sections/Projects.vue';
import Donation from './sections/Donation.vue';

export default {
  name: 'HomePage',
  components: { Header, KnowUs, WhatWeDo, Projects, Donation },
};
</script>

<style scoped></style>
