<template>
  <div>
    <Header />
    <HowToContribute />
    <Donations />
  </div>
</template>

<script>
import Header from './sections/Header.vue';
import HowToContribute from './sections/HowToContribute.vue';
import Donations from '../homepage/sections/Donation.vue';
export default {
  name: 'Donation',
  components: { Header, HowToContribute, Donations },
};
</script>
