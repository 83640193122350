<template>
  <div>
    <section id="know-us">
      <div class="heading-dash">
        <span class="side-dash"></span>
        <h5>KNOW ABOUT US</h5>
      </div>

      <div class="flex-layout">
        <div class="content">
          <h1 class="heading">
            We provide a place for children with special needs
          </h1>

          <p class="content-p">
            We are an NGO set out to elighthen the world agaist the myths
            surrouding special needs children. We try to help them overcome
            their developmental challenges in order to contribute their quota to
            national development
          </p>

          <p class="content-p">
            This elighthenment is done through massive enlightenment programs.
            As the world celebrates these children every 21st March, Larger Than
            I Developmental is saying LOVE Special needs children. Special needs
            children are human beings like us. Many people living in the world
            with special needs have normal lives, and are making strides in
            sports and working in offices with other regular counterparts.
          </p>

          <Button
            :text="'Learn More'"
            :color="'#000000'"
            :background="'#F2C94C '"
            @click.native="$router.push('/about-us')"
          />
        </div>

        <div class="image">
          <img src="../../../assets/pic1.png" alt="image" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Button from '../../../components/Button.vue';
export default {
  name: 'KnowUs',
  components: { Button },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .heading {
    width: 100% !important;
  }
}
</style>
