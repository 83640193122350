import HomePage from './pages/homepage/HomePage.vue';
import AboutPage from './pages/aboutpage/AboutPage.vue';
import WhatWeDoPage from './pages/whatwedopage/WhatWeDoPage.vue';
import MediaPage from './pages/mediapage/MediaPage.vue';
import ContactPage from './pages/contactpage/ContactUs.vue';
import DonationPage from './pages/donationpage/Donation.vue';
import ProjectPage from './pages/projectpage/Project.vue';
import EventPage from './pages/eventpage/Event.vue';
import AddEvent from './pages/addeventpage/AddEvent.vue';

//Admin
import Login from './admin/Login.vue';

export default [
  { path: '/', component: HomePage },
  { path: '/about-us', component: AboutPage },
  { path: '/what-we-do', component: WhatWeDoPage },
  { path: '/media', component: MediaPage },
  { path: '/contact-us', component: ContactPage },
  { path: '/donation', component: DonationPage },
  { path: '/project', component: ProjectPage },
  { path: '/event/:id', component: EventPage },
  { path: '/781227/add-event', component: AddEvent },
  { path: '/admin-login', component: Login },
];
