<template>
  <section>
    <div class="form">
      <div class="input">
        <label for="firstName">First Name</label>
        <input type="text" />
      </div>

      <div class="input">
        <label for="lastName">Last Name</label>
        <input type="text" />
      </div>

      <div class="input">
        <label for="lastName">Email Address</label>
        <input type="text" />
      </div>

      <div class="input">
        <label for="lastName">Subject</label>
        <input type="text" />
      </div>

      <div class="input text-area">
        <textarea
          name="message"
          id=""
          cols="30"
          rows="10"
          placeholder="Type your Messege"
        ></textarea>
      </div>
      <div class="button">
        <Button
          :text="'Send message'"
          :color="'#000'"
          :background="'#F2C94C'"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Button from '../../../components/Button.vue';
export default {
  name: 'ContactForm',
  components: { Button },
};
</script>

<style scoped>
section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  width: 70%;
}

input {
  display: block;
  border: none;
  border-bottom: 1px solid #1d2130;
  width: 100%;
  padding: 1rem 0;
  box-sizing: border-box;
  font-size: 16px;
}

.text-area,
.button {
  grid-column: 1/3;
}

textarea {
  padding: 1rem;
  width: 100%;
}

.button {
  display: flex;
  justify-content: center;
}
</style>
