<template>
  <section>
    <div>
      <h1 class="heading">Our Events</h1>
      <div class="layout" v-if="!loading">
        <ImageCard
          v-for="(e, i) in events"
          :imageUrl="e.flyer"
          :key="i"
          :photosCount="e.photos.length"
          :id="e._id"
        />
      </div>

      <div class="layout loading" v-if="loading">
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import ImageCard from '../../../components/ImageCard.vue';
import Skeleton from '../../../components/Skeleton.vue';
export default {
  name: 'Events',
  components: { ImageCard, Skeleton },
  data: () => ({
    events: [],
    loading: false,
  }),

  async created() {
    try {
      this.loading = true;
      const events = await axios.get('/events');

      this.events = events.data.doc;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log(error.response);
    }
  },
};
</script>

<style scoped>
.layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  grid-gap: 1rem;
}
</style>
