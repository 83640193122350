import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import Vuesax from 'vuesax';
import Flutterwave from 'vue-flutterwave';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueTransmit from 'vue-transmit';
import axios from 'axios';

import routes from './routes';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

// axios.defaults.baseURL = 'https://ltiportfolio.herokuapp.com/api/v1';
axios.defaults.baseURL = 'https://lti-portfolio-backend.onrender.com/api/v1';
// axios.defaults.baseURL = 'http://127.0.0.1:5000/api/v1';

Vue.config.productionTip = false;

export const bus = new Vue();

export const router = new VueRouter({
  routes,
  mode: 'history',
});

import 'vuesax/dist/vuesax.css'; //Vuesax styles
Vue.use(Vuesax);

Vue.use(VueRouter);

Vue.use(Flutterwave, {
  publicKey: 'FLWPUBK-af915650c126637de43a37780cc3edb7-X',
});

//AIzaSyB7k3jY1dCoNDUgLsA6EUWJMIv40oYkUHQ
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBMpTEA4nexkVy2q76tkXSHtjV5WkYB2_E',
    libraries: 'places',
  },
});

Vue.use(VueTransmit);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
