export default [
  {
    name: 'Pst Kemi Ogiemudia',
    role: 'President LTIDF',
    image: require('./images/WhatsApp Image 2022-05-30 at 2.50.28 PM.jpeg'),
  },

  {
    name: 'Dr Christine Gabriel-Brisibe',
    role: 'LTIDF Vice President',
    image: require('./images/WhatsApp Image 2022-05-31 at 12.23.14 PM.jpeg'),
    bio: 'Christine Gabriel-Brisibe is a medical doctor and lecturer in the department of Medical Biochemistry, College of Medical Sciences, Rivers State University. She was Head of her department from 2018-2021. She is also a Singer , Author and Public Speaker . She is passionate about Leadership and emotional well-being. She enjoys playing the piano in worship and is married with two lovely children',
  },

  {
    name: 'Dr. Michael Ebiegberi',
    role: 'Board Member',
    image: require('./images/WhatsApp Image 2022-06-04 at 7.33.25 AM.jpeg'),
  },

  {
    name: 'Mrs Blessing Adegbemi',
    role: ' Director of Programs',
    image: require('./images/WhatsApp Image 2022-05-24 at 3.22.04 PM.jpeg'),
  },

  {
    name: 'Dr Ebi Totyen',
    role: 'Director of medical Services LTIDF',
    image: require('./images/WhatsApp Image 2022-05-30 at 12.50.02 PM.jpeg'),
    bio: `Dr Ebi Linda TOTYEN
    Consultant Ear, Nose, Throat, Head and Neck surgeon, 
    HOD, ENT Dept
    Federal Medical Centre, Yenagoa,
    Bayelsa State`,
  },

  {
    name: 'Mrs Joanna Torru',
    role: ' Member of the Board of Trustees. Director of Finance',
    image: require('./images/WhatsApp Image 2022-05-30 at 2.52.47 PM.jpeg'),
  },

  {
    name: 'Deac. Izontimi Otuogha',
    role: 'Secretary of Larger Than I Developmental Foundation (LTIDF)',
    image: require('./images/WhatsApp Image 2022-06-01 at 10.46.10 AM.jpeg'),
    bio: `RE: Izontimi Otuogha is the Secretary of Larger Than I Developmental Foundation (LTIDF) and the Foundation Director of Publicity.
    He has a B. A (Ed.) Degree in English, from the University of Port Harcourt, and a Diploma in Journalism from the International Institute of Journalism, Abuja.
    He is a media practitioner, with over 25 years active working experience, cutting across the electronic and print media.
    He is married, with children.`,
  },

  {
    name: 'Mrs Betty Arofah',
    role: 'Director of Welfare',
    image: require('./images/betty-afora.jpg'),
    bio: `She holds a BA (Hons) degree in French  from University of Portharcourt 
    Administrator - Bayelsa State Chief Judge’s Chambers 
    Unapologetically dyslexic, and passionate to create awareness for Dyslexia and other Developmental and learning Disorders. She is a strong Spokesperson for children with learning disabilities, using herself as a case in point.
    `,
  },

  {
    name: 'Mrs Ebinyo Thomas-Ako',
    role: 'Director of Education',
    image: require('./images/WhatsApp Image 2022-06-03 at 3.03.54 PM.jpeg'),
    bio: `She is from Okoroba community in Nembe Local Government Area in Bayelsa State.She has a  BSc degree in Geology from the University of Port Harcourt,a post graduate degree on Education from the University of Sokoto,a masters  degree in Educational Management from Niger Delta University ,Bayelsa State.She is the vice principal of Saint Judes Girls Secondary School,Amarata yenagoa.Bayelsa State.She is married with children`,
  },

  {
    name: 'Mr Preye Isenah',
    role: 'Board Member',
    image: require('./images/WhatsApp Image 2022-10-13 at 10.34.17 AM.jpeg'),
  },
];
