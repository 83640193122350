<template>
  <div>
    <Header />
    <ContactForm />
    <GoogleMap />
  </div>
</template>

<script>
import Header from './sections/Header.vue';
import ContactForm from './sections/ContactForm.vue';
import GoogleMap from './sections/GoogleMap.vue';
export default {
  name: 'ContactUs',
  components: { Header, ContactForm, GoogleMap },
};
</script>
