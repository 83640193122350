<template>
  <section>
    <div>
      <div class="showcase">
        <div class="tint"></div>
        <div class="show">
          <h1 class="heading">
            You can contribute to provide a place for children with special
            needs!
          </h1>

          <div class="buttons flex-layout">
            <Button
              :text="`Join as a volunteer`"
              :background="`#F2C94C`"
              :color="`#000000`"
              @click.native="$router.push('/contact-us')"
            />

            <Button
              :text="`Donate`"
              :background="`#FFFFFF`"
              :color="`#1D2130`"
              @click.native="active = true"
            />
          </div>
        </div>
      </div>
    </div>

    <vs-dialog width="300px" not-center v-model="active">
      <template #header>
        <h4 class="not-margin">Enter Detail Here:-</h4>
      </template>

      <div class="con-content">
        <input type="text" placeholder="Enter First Name" v-model="firstName" />

        <input type="text" placeholder="Enter Last Name" v-model="lastName" />

        <input type="text" placeholder="Enter Email" v-model="email" />

        <input type="number" placeholder="Enter Amount" v-model="amount" />
      </div>

      <template #footer>
        <div class="con-footer">
          <Button
            :text="`Initiate Payment`"
            :background="`#F2C94C`"
            :color="`#000000`"
            @click.native="makePayment"
          />
        </div>
      </template>
    </vs-dialog>
  </section>
</template>

<script>
import Button from '../../Button.vue';
export default {
  name: 'Contribute',
  components: { Button },

  data: () => ({
    email: '',
    amount: null,
    active: false,
    firstName: '',
    lastName: '',
  }),

  methods: {
    makePayment() {
      this.$launchFlutterwave({
        tx_ref: Date.now(),
        amount: this.amount,
        currency: 'NGN',
        payment_options: 'card',
        customer: {
          email: this.email,
          phone_number: this.phone,
          name: `${this.firstName} ${this.lastName}`,
        },
        callback: function ({ status }) {
          if (status === 'successful') {
            this.active = false;
          }
        },
        customizations: {
          title: 'Larger Than I Developemental Foundation',
          description: 'Donation',
          logo: 'LargerthanI',
        },
      });
    },
  },
};
</script>

<style scoped>
.showcase {
  background: url('../../../assets/pic2.png') no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 20px;
  text-align: center;
}

.show {
  padding: 4.5rem 3rem;
  z-index: 2;
  position: relative;
}

h1 {
  color: #fff;
  font-weight: 700;
  margin: 0 0 3rem 0;
  line-height: 57.6px;
  width: 80%;
  margin: 3rem auto;
}

.buttons {
  width: 40%;
  justify-content: space-around;
  margin: 0 auto;
}

.con-footer {
  display: flex;
  justify-content: center;
}

input {
  display: block;
  width: 100%;
  border-radius: 4px;
  padding: 1rem;
  box-sizing: border-box;
  background: 0% 0% / 1rem rgba(0, 0, 0, 0.05);
  border: none;
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  h1 {
    width: 100%;
  }
}
</style>
