<template>
  <div>
    <section>
      <div class="showcase">
        <img
          src="../../../assets/WhatsApp1.jpeg"
          alt=""
        />
      </div>

      <div class="flex-layout">
        <div class="content">
          <h5>our vision</h5>
          <h3>
            We make sure to provide inclusive care for children with special
            needs
          </h3>

          <p class="content-p">
            To reduce the rate of disability through early intervention, and
            enable children age 0 -12 years with mental and physical challenges
            overcome their disability and other social-cultural and religious
            stigmas in order to live up to their full potentials.
          </p>
        </div>

        <div class="content">
          <h5>our mission</h5>
          <h3>
            We make sure to provide inclusive care for children with special
            needs
          </h3>

          <p class="content-p">
            To imporove the lives of challenged children through organised
            outreaches, seminars and awareness campaigns and also by
            establishing health, educational and rehabilitation/counselling
            centres to enable families locate, obtain and use relevant resources
            and services for their children to overcome thier challenges be
            fully integrated into society.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ShowCase',
};
</script>

<style scoped>
section {
  background: #fcedc6;
  position: relative;
  top: 250px;
}

.showcase {
  position: relative;
}

img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  position: relative;
  top: -250px;
  border-radius: 12px;
}

h3 {
  font-size: 28px;
}

h5 {
  font-size: 16px;
  text-transform: uppercase;
}

.flex-layout {
  padding: 4rem;
  margin-top: -250px;
}

@media (max-width: 600px) {
  .flex-layout {
    padding: 0;
  }
}
</style>
