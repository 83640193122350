<template>
  <section>
    <div class="heading-dash">
      <span class="side-dash"></span>
      <h5>KNOW ABOUT US</h5>
    </div>

    <div class="flex-layout">
      <div class="content">
        <h1 class="heading">We are a non-governmental organization</h1>
      </div>

      <div class="content">
        <h4>About Larger Than I Developmental Foundation</h4>

        <p class="content-p">
          Larger Than I Developmental Foundation (LTIDF) is a Non-Governmental
          Organizarion instituitionalized in 2014, having been in operation
          since 2007, with its headquarter located in Yenagoa, the capital of
          Bayelsa State, Nigeria. Our focus is to advocate for challenged
          children and families with special needs children, to enable them
          locate, obtain, and use relevant and available resources such as early
          clinical diagnosis and educational services, in order to enable the
          children overcome social stigma and limitations, so that they can live
          up to their full potentials. Our target audience is children between
          the age of 0-12 years
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'KnowAboutUs',
};
</script>
