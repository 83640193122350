<template>
  <section>
    <div class="flex-layout">
      <div class="content">
        <h1 class="heading">{{ event.name }}</h1>

        <p>
          {{ event.description }}
        </p>
      </div>

      <div class="image">
        <img :src="event.flyer" alt="event" srcset="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Header',
  props: { event: Object },
};
</script>

<style scoped>
.heading {
  text-transform: capitalize;
}

img {
  width: 100%;
  height: auto;
}

.image {
  height: auto;
}
</style>
