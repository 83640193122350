<template>
  <div>
    <Header />
    <ForKids />
    <Projects />
  </div>
</template>

<script>
import Header from './sections/Header.vue';
import ForKids from './sections/ForKids.vue';
import Projects from '../homepage/sections/Projects.vue';
export default {
  name: 'WhatWeDo',
  components: { Header, ForKids, Projects },
};
</script>
