<template>
  <section>
    <div class="heading-dash">
      <span class="side-dash"></span>
      <h5>CONTACT US</h5>
    </div>
    <div class="flex-layout">
      <div class="content left">
        <h1 class="heading">We'd love to hear from you</h1>

        <p class="content-p">
          Have any question in mind or want to enquire? Please feel free to
          contact us through the form or the following details.
        </p>
      </div>

      <div class="content">
        <div class="contact">
          <h4>Let's talk!</h4>
          <span>+234 08181267012</span>
          <span>Largerthanifoundation@gmail.com</span>
        </div>

        <!-- <div class="contact">
          <h4>Headoffice</h4>
          <p class="content-p">8 Brewery Drive, Lagos, Nigeria.</p>
        </div> -->

        <div class="contact">
          <h4>Head Office</h4>
          <p class="content-p">
            NO. 43 old pdp secretarial road, yenizue-gene, yenegoa, Bayelsa
            State.
          </p>
        </div>

        <div class="contact">
          <span><i class="fa fa-building"></i></span>
          <span><i class="fa fa-building"></i></span>
          <span><i class="fa fa-building"></i></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style scoped>
section {
  background: #fcedc6;
}

h1 {
  font-size: 56px;
}

h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.contact {
  margin-bottom: 3rem;
}

.contact span {
  margin: 0 10px;
}

.left {
  padding-left: 85px;
}
</style>
