<template>
  <div id="app">
    <Navbar />
    <router-view></router-view>
    <div class="footer" :class="{ applyMargin: inAbout }">
      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/footer/Footer.vue';
export default {
  name: 'App',

  components: { Navbar, Footer },

  computed: {
    inAbout() {
      return this.$route.path === '/about-us';
    },
  },
};
</script>

<style>
.footer {
  position: relative;
}

.applyMargin {
  margin-top: 250px;
}
</style>
