<template>
  <div class="card">
    <div class="card-img skeleton">
      <!-- waiting for img to load from javascript -->
    </div>
  </div>
</template>

<script>
export default { name: 'Skeleton' };
</script>

<style scoped>
*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #f2f5f7;
}

.card {
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.card-img {
  padding-bottom: 56.25%;
  position: relative;
}
.card-img img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  -webkit-animation: shine 1s ease infinite;
  animation: shine 1s ease infinite;
  height: 207px;
}

@-webkit-keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
</style>
