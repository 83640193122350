<template>
  <div>
    <div class="container" @click="redirect">
      <div class="content">
        <div class="content-overlay"></div>
        <img
          class="content-image"
          :src="imageUrl ? imageUrl : 'https://placekitten.com/801/800'"
        />
        <div class="content-details fadeIn-bottom">
          <!-- <h3 v-if="!editMode" class="content-title">This is a title</h3> -->
          <p v-if="!editMode" class="content-text">
            <i class="fas fa-image"></i> {{ photosCount }}
          </p>
          <span v-if="editMode" class="content-text" @click="remove"
            ><i class="fas fa-trash"></i>
          </span>
          <span v-if="editMode" class="content-text" @click="active = !active"
            ><i class="fa fa-pencil" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <template>
      <div class="center">
        <vs-dialog width="300px" not-center v-model="active">
          <template #header>
            <h4 class="not-margin">Input Caption</h4>
          </template>

          <div class="con-content">
            <textarea name="" v-model="caption"></textarea>
          </div>

          <template #footer>
            <div class="con-footer">
              <vs-button @click="addCaption" transparent> Ok </vs-button>
              <vs-button @click="active = false" dark transparent>
                Cancel
              </vs-button>
            </div>
          </template>
        </vs-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import { bus } from '../main';
export default {
  name: 'ImageCard',
  props: {
    editMode: Boolean,
    imageUrl: String,
    title: String,
    file: Object,
    index: Number,
    photosCount: Number,
    id: String,
  },

  data: () => ({
    active: false,
    caption: '',
  }),

  methods: {
    redirect() {
      if (!this.editMode) {
        this.$router.push(`/event/${this.id}`);
      }
    },

    remove() {
      bus.$emit('file-remove', this.file);
    },

    addCaption() {
      bus.$emit('add-caption', { caption: this.caption, index: this.index });
      this.active = false;
    },
  },
};
</script>

<style scoped>
.fadeIn-bottom p {
  color: #fff;
}

textarea {
  width: 100%;
  box-sizing: border-box;
}

.con-footer {
  display: flex;
  justify-content: space-between;
}

.main-title {
  color: #2d2d2d;
  text-align: center;
  text-transform: capitalize;
  padding: 0.7em 0;
}

.container {
  padding: 1em 0;
  /* float: left; */
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .container {
    display: block;
    width: 100%;
  }
}

.container .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  position: relative;

  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-image {
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details span {
  color: #fff;
  font-size: 0.8em;
  margin: 2em;
}

.fadeIn-bottom {
  top: 80%;
}
</style>
