<template>
  <div>
    <Overview />
    <Achievements />
    <Projects />
  </div>
</template>

<script>
import Overview from './sections/Overview.vue';
import Achievements from './sections/Achievements.vue';
import Projects from '../homepage/sections/Projects.vue';
export default {
  name: 'Project',
  components: { Overview, Achievements, Projects },
};
</script>
