<template>
  <section>
    <h1 class="heading">Our Sponsors</h1>
    <div class="layout">
      <img
        src="../images/sponsor1.jpeg"
        alt="sponsor"
      />
      <img
        src="../images/sponsor2.jpeg"
        alt="sponsor"
      />
      <img
        src="../images/sponsor3.jpeg"
        alt="sponsor"
      />
      <img
        src="../images/sponsor4.jpeg"
        alt="sponsor"
      />
      <img
        src="../images/sponsor5.jpeg"
        alt="sponsor"
      />
      <img
        src="../images/sponsor6.jpeg"
        alt="sponsor"
      />
      <img
        src="../images/sponsor7.jpeg"
        alt="sponsor"
      />
      <img
        src="../images/sponsor8.jpeg"
        alt="sponsor"
      />
      <img
        src="../images/sponsor9.jpeg"
        alt="sponsor"
      />
      <img
        src="../images/sponsor10.jpeg"
        alt="sponsor"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Sponsors',
};
</script>

<style scoped>
.layout {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
}

img {
  width: 150px;
}

h1 {
  text-align: center;
}

@media (max-width: 600px) {
  .layout {
    grid-template-columns: 1fr;
    place-items: center;
  }
}
</style>
