<template>
  <section id="what-we-do">
    <div class="wrapper">
      <div class="services grid-layout">
        <div class="service">
          <span><i class="fa fa-baby"></i></span>
          <div class="service-content">
            <h4>20+ orphanage visit in 2 months</h4>
            <p class="content-p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros.
            </p>
          </div>
        </div>

        <div class="service">
          <span><i class="fa fa-leaf"></i></span>
          <div class="service-content">
            <h4>230 People Have donated</h4>
            <p class="content-p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros.
            </p>
          </div>
        </div>

        <div class="service">
          <span><i class="fa fa-users" aria-hidden="true"></i> </span>
          <div class="service-content">
            <h4>30+ people joined</h4>
            <p class="content-p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros.
            </p>
          </div>
        </div>

        <div class="service">
          <span><i class="fa fa-handshake" aria-hidden="true"></i> </span>
          <div class="service-content">
            <h4>1.2m Raised for this initiate</h4>
            <p class="content-p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhatWeDo',
};
</script>

<style scoped>
section {
  background: #fcedc6;
}

.wrapper {
  padding: 5rem;
}

.service {
  display: flex;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

h4 {
  margin: 0;
  font-size: 24px;
}

.service span {
  font-size: 18px;
  border-radius: 4px;
  color: #fcedc6;
  background: #000;
  height: fit-content;
  padding: 8px;
}

.service-content {
  margin-left: 15px;
}

p {
  margin-top: 15px;
}
</style>
