<template>
  <div>
    <Header />
    <Events />
  </div>
</template>

<script>
import Header from './sections/Header.vue';
import Events from './sections/Events.vue';
export default {
  name: 'Media',
  components: { Header, Events },
};
</script>
