<template>
  <div>
    <Header :event="event" />
    <Gallery v-if="!loading" :images="images" />

    <div v-if="loading" class="grid-layout">
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Header from './sections/Header.vue';
import Gallery from './sections/Gallery.vue';
import Skeleton from '../../components/Skeleton.vue';

export default {
  name: 'Event',
  components: { Header, Gallery, Skeleton },

  data: () => ({
    event: {},
    images: [],
    loading: false,
  }),

  async created() {
    this.loading = true;

    const { id } = this.$route.params;

    const response = await axios.get(`events/${id}`);

    const { photos, ...event } = response.data.doc;

    this.event = event;

    this.images = photos;

    this.loading = false;
  },
};
</script>

<style scoped>
.grid-layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}
</style>
