<template>
  <section>
    <div class="flex-layout">
      <div class="content">
        <h1 class="heading">How we spend your donations and where it goes</h1>
        <p class="content-p">
          We understand that when you make a donation, you want to know exactly
          where your money is going and we pledge to be transparent.
        </p>

        <div class="donations flex-layout">
          <span class="donation flex-layout">
            <span class="donor first"></span>
            <span class="text">40% child care home</span>
          </span>

          <span class="donation flex-layout">
            <span class="donor second"></span>
            <span class="text">35% cleanliness program</span>
          </span>

          <span class="donation flex-layout">
            <span class="donor third"></span>
            <span class="text">10% helping people</span>
          </span>

          <span class="donation flex-layout">
            <span class="donor fourth"></span>
            <span class="text">10% excursions</span>
          </span>

          <span class="donation flex-layout">
            <span class="donor fifth"></span>
            <span class="text">5% feeding the poor</span>
          </span>
        </div>
      </div>

      <div class="content">
        <PieChart />
      </div>
    </div>
  </section>
</template>

<script>
import PieChart from './PieChart.vue';
export default {
  name: 'Donation',
  components: { PieChart },
};
</script>

<style scoped>
section {
  background: #000;
  color: #fff;
}

.donor {
  height: 16px;
  width: 16px;
  display: inline-block;
}

.donations {
  flex-wrap: wrap;
  justify-content: start;
}

.donation {
  margin: 10px;
}

.text {
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
}

.first {
  background: #bef3c0;
}

.second {
  background: #ac94f1;
}

.third {
  background: #fff0ca;
}

.fourth {
  background: #f9cf64;
}

.fifth {
  background: #f38fbf;
}

@media (max-width: 600px) {
  .donations {
    align-items: start;
  }

  .donation {
    flex-direction: row;
  }
}
</style>
