<template>
  <div class="vld-parent">
    <loading
      :active.sync="loading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      :color="'#f2c94c'"
      :background-color="'#1a1919'"
    ></loading>

    <label><input type="checkbox" v-model="fullPage" />Full page?</label>
    <button @click.prevent="doAjax">fetch Data</button>
  </div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  data() {
    return {
      fullPage: true,
    };
  },

  props: {
    isLoading: Boolean,
  },

  components: {
    Loading,
  },
  methods: {
    doAjax() {
      this.isLoading = true;
      // simulate AJAX
      setTimeout(() => {
        this.isLoading = false;
      }, 5000);
    },
    onCancel() {
      console.log('User cancelled the loader.');
    },
  },

  computed: {
    loading() {
      return this.isLoading;
    },
  },
};
</script>
