<template>
  <div>
    <!-- <gmap-map :zoom="14" :center="center" style="width: 100%; height: 500px">
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m"
        @click="center = m"
      ></gmap-marker>


    </gmap-map> -->

    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.0410709542125!2d6.321450414090482!3d4.932782940976562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x106a03a9b9f3e49d%3A0x6752a2608c61c471!2sLarger%20Than%20I%20International%20Schools!5e0!3m2!1sen!2sng!4v1654865954854!5m2!1sen!2sng"
      width="100%"
      height="450"
      style="border: 0"
      allowfullscreen="yes"
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  data: () => ({
    center: { lat: 4.932991, lng: 6.323607 },

    currentPosition: {},

    markers: [{ lat: 4.932991, lng: 6.323607 }],
    paths: [],
  }),

  mounted() {
    this.locateGeoLocation();
  },

  methods: {
    initMarker(loc) {
      this.existingPlace = loc;
    },

    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.currentPosition = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };

        this.paths = [this.currentPosition, this.center];
      });
    },
  },
};
</script>
