<template>
  <section id="what-we-do">
    <div class="heading-dash">
      <span class="side-dash"></span>
      <h5>WHAT WE DO</h5>
    </div>

    <div class="flex-layout">
      <div class="content">
        <h1 class="heading">Some services we provide for our children</h1>

        <p>
          We provide a series of services for our special needs children,
          ranging from parent to parent support to child therapy.
        </p>

        <div class="services">
          <div class="service">
            <span><i class="fa fa-building"></i></span>
            <div class="service-content">
              <h4>Family support</h4>
              <p class="content-p">
                We provide support for families with a member with a disability,
                which may include a child, an adult or even the parent in the
                family.
              </p>
            </div>
          </div>

          <div class="service">
            <span><i class="fa fa-leaf"></i></span>
            <div class="service-content">
              <h4>Health benefits</h4>
              <p class="content-p">
                We provide health benefits for our special needs kids. Adding
                physical exercise to the schedules of the children can result in
                strength improvements, increased stability, improved bone
                health, and improved stamina and cardiovascular fitness.
              </p>
            </div>
          </div>

          <div class="service">
            <span
              ><i class="fa fa-graduation-cap" aria-hidden="true"></i>
            </span>
            <div class="service-content">
              <h4>Scholarships</h4>
              <p class="content-p">
                We provide scholarships on all levels for our special needs
                children
              </p>
            </div>
          </div>

          <div class="service">
            <span><i class="fa fa-heartbeat" aria-hidden="true"></i> </span>
            <div class="service-content">
              <h4>Therapy</h4>
              <p class="content-p">
                We provide therapy or our special needs children. For example
                Applied behavior analysis (ABA) is often referred to as the gold
                standard of autism therapy, largely because therapists set very
                specific, measurable goals and often succeed in teaching skills
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="image">
        <!-- <img
          src="../../../assets/alireza-attari-SBIak0pKUIE-unsplash.jpg"
          alt="photo"
        /> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhatWeDo',
};
</script>

<style scoped>
section {
  background: #fcedc6;
}

.service {
  display: flex;
}

h4 {
  margin: 0;
  font-size: 24px;
}

.service span {
  font-size: 18px;
  border-radius: 4px;
  color: #fcedc6;
  background: #000;
  height: fit-content;
  padding: 8px;
}

.service-content {
  margin-left: 15px;
}
</style>
