<template>
  <section>
    <div class="flex-layout wrapper">
      <div class="content">
        <h5>OUR JOURNEY</h5>
        <h2 class="heading">How we started</h2>

        <p class="content-p">
          It was inaugurated SEPTEMBER 26TH, 2014, with the Theme: VOICE FOR THE
          VOICELESS. Larger Than I Developmental Foundation is a FAITH BASED
          Organization. Throughout the years since then, we have organised annual
          outreaches in several communities in Bayelsa State, reaching out to
          mentally, physically, behaviorally and sensory challenged people and
          their custodians.
        </p>

        <div class="flex-layout stats">
          <div>
            <h4>34M+</h4>
            <p>Donation Received</p>
          </div>

          <div>
            <h4>100+</h4>
            <p>Volunteers</p>
          </div>

          <div>
            <h4>100+</h4>
            <p>Children under care</p>
          </div>
        </div>
      </div>

      <div class="image">
        <img src="../../../assets/pic2.png" alt="" srcset="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Journey',
};
</script>

<style scoped>
section {
  position: relative;
  top: 250px;
}

.wrapper {
  border-radius: 12px;
}

.flex-layout {
  padding: 2rem;
  background: #000;
  color: #fff;
}

.image {
  height: auto;
}

h5 {
  font-size: 16px;
  letter-spacing: 1px;
}

h4 {
  font-size: 24px;
  margin-bottom: 15px;
}

p {
  margin: 0;
  opacity: 1;
  line-height: 160%;
}

@media (max-width: 600px) {
  .stats {
    text-align: center;
  }
}
</style>
