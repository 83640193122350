<template>
  <button :style="style">{{ text }}</button>
</template>

<script>
export default {
  name: 'Button',

  props: {
    text: String,
    background: String,
    color: String,
  },

  computed: {
    style() {
      return `background: ${this.background};
       color: ${this.color};
      `;
    },
  },
};
</script>

<style scoped>
button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 32px;
  border-radius: 4px;
  border: none;
}

@media (max-width: 600px) {
  button {
    margin-bottom: 1rem;
  }
}
</style>
