<template>
  <div>
    <section id="header">
      <h1>Inclusive care for children with special needs</h1>

      <div class="buttons">
        <Button :text="`What we do`" :color="'#1D2130'" />
      </div>

      <div class="stats">
        <span>230 children under our care</span>
        <div class="divider"></div>
        <span>58 donations collected</span>
      </div>
    </section>
  </div>
</template>

<script>
import Button from '../../../components/Button.vue';
export default {
  name: 'Header',
  components: { Button },
};
</script>

<style scoped>
#header {
  background: linear-gradient(
      93.72deg,
      rgba(0, 0, 0, 0.55) 47.34%,
      rgba(0, 0, 0, 0) 75.43%
    ),
    url('../../../assets/pic1.png');

  background-size: cover;
  background-position: center;
}

#header h1 {
  font-size: 64px;
  width: 50%;
  color: #fff;
}

.stats {
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 8rem 0 1rem 0;
}

.stats span {
  width: 20%;
  font-weight: 500;
}

.divider {
  background-color: #e5e5e5;
  height: 0.4px;
  width: 60%;
  backdrop-filter: blur(30px);
}

@media (max-width: 600px) {
  #header h1 {
    font-size: 48px;
    width: 100%;
  }

  .stats {
    flex-direction: column;
  }

  .stats span {
    width: 100%;
    padding: 10px;
  }

  .divider {
    width: 100%;
  }
}
</style>
