<template>
  <section>
    <div class="heading-dash">
      <span class="side-dash"></span>
      <h5>OUR GOALS</h5>
    </div>
    <div class="flex-layout">
      <div class="content">
        <h1 class="heading">
          Our goal is to provide inclusive care for children with special needs
        </h1>

        <p class="content-p">
          We make available relevant resources and services, such as
          adequate/early diagnosis, special health care provisions, educational
          services and advocacy programs, to meet the special needs of
          challenged children.
        </p>

        <Button
          :text="`Read more`"
          :color="`#000`"
          :background="`#F2C94C
`"
        />
      </div>

      <div class="image">
        <!-- <img
        
      src="../../../assets/group-kids.JPG"
        /> -->
      </div>
    </div>
  </section>
</template>

<script>
import Button from '../../../components/Button.vue';
export default {
  name: 'Header',
  components: { Button },
};
</script>

<style scoped>
section {
  background: #fcedc6;
}

.goals {
  background: #fff;
  padding: 1.5rem;
  border-radius: 20px;
}

.goal {
  margin-bottom: 1rem;
}

.goal div {
  width: 49%;
}

.flex-layout img {
  width: auto;
}

p {
  font-size: 16px;
  color: #525560;
  line-height: 25.6px;
}

h4 {
  font-size: 20px;
}

@media (max-width: 600px) {
  .goal div {
    width: 100%;
  }

  .flex-layout img {
    width: 100%;
  }
}
</style>
