<template>
  <section :class="{ makeGrey: makeGrey }" id="projects">
    <div class="heading-dash">
      <span class="side-dash"></span>
      <h5>KNOW ABOUT US</h5>
    </div>

    <h1 class="heading">We provide a place for children with special needs</h1>

    <div class="cards flex-layout">
      <div class="card first">
        <h3>Our Special School</h3>
        <p class="content-p">
          The foundation has set up an inclusive school and training centre
          where all children can learn without prejudice. The children from
          communities are enrolled in the state special school as we locate
          them. The foundation transports them from their community to and fro
          the school. They are given other interventions like audiology checks,
          hearing aids and medical services at reduced cost at the Federal
          Medical Center.
        </p>

        <div>
          <Button
            @click.native="$router.push('/about-us')"
            :text="`See more`"
            :color="`#1D2130`"
            :background="`#fff`"
          />
        </div>
      </div>

      <div class="card second">
        <h3>Celebration of Our Special Kids</h3>
        <p class="content-p">
          Every year, the children are given a fun time in a program that marks
          the World Down Syndrome Day on 21st March and Autism Awareness Day
          celebration on 2nd April. These two are marked together with an
          Inclusive Play for children with disabilities playing and having fun
          with other children with no obvious disabilities.
        </p>

        <div>
          <Button
            @click.native="$router.push('/about-us')"
            :text="`See more`"
            :color="`#1D2130`"
            :background="`#fff`"
          />
        </div>
      </div>
      <div class="card third">
        <h3>Creation Of Public Awareness</h3>
        <p class="content-p">
          Foundatioon is bent on educating the genral public about special needs
          children, particularly hinging on the fact that these disorders are
          genetic and are not spiritual attacks, and again, since they're purely
          genetic, are not contagious
        </p>

        <div>
          <Button
            @click.native="$router.push('/about-us')"
            :text="`See more`"
            :color="`#1D2130`"
            :background="`#fff`"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from './../../../components/Button.vue';
export default {
  name: 'Projects',
  components: { Button },
  props: { makeGrey: Boolean },
};
</script>

<style scoped>
.heading {
  width: 50%;
}

h3 {
  font-size: 28px;
  min-height: 80px;
}

.card {
  padding: 3rem 2rem;
  opacity: 0.5;
  border-radius: 20px;
  color: #fff;
  margin: 0 1rem;
  position: relative;
  font-weight: bold;
}

.first {
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url('../../../assets/pic2.png');
  background-size: cover;
  background-position: center;
}
.second {
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url('../../../assets/pic3.png');
  background-size: cover;
  background-position: center;
}

.third {
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url('../../../assets/kenny-eliason-1-aA2Fadydc-unsplash.jpg');
  background-size: cover;
  background-position: center;
}

.content-p {
  margin-bottom: 5rem;
  color: #fff;
}

.makeGrey {
  background: #e5e5e5;
}

.flex-layout .card {
  width: 32%;
}

@media (max-width: 600px) {
  .heading {
    width: 100%;
  }

  .card {
    margin-bottom: 1rem;
  }

  .flex-layout .card {
    width: 85%;
  }
}
</style>
