<template>
  <section>
    <div class="wrapper">
      <div class="heading-dash">
        <span class="side-dash"></span>
        <h5>OUR PROJECT</h5>
      </div>

      <div class="content-push">
        <h1 class="heading">Mission Smile 1k: Outdoor charity outreach</h1>

        <p class="content-p">
          Et morbi vitae lobortis nam odio. Faucibus vitae vel neque nullam in
          in lorem platea mattis. Euismod aenean rhoncus scelerisque amet
          tincidunt scelerisque aliquam. Luctus porttitor elit vel sapien,
          accumsan et id ut est. Posuere molestie in turpis quam. Scelerisque in
          viverra mi ut quisque. In sollicitudin sapien, vel nulla quisque
          vitae. Scelerisque eget accumsan, non in. Posuere magna erat bibendum
          amet, nisi eu id.
        </p>

        <p class="content-p">
          Et morbi vitae lobortis nam odio. Faucibus vitae vel neque nullam in
          in lorem platea mattis. Euismod aenean rhoncus scelerisque amet
          tincidunt scelerisque aliquam. Luctus porttitor elit vel sapien,
          accumsan et id ut est. Posuere molestie in turpis quam. Scelerisque in
          viverra mi ut quisque. In sollicitudin sapien, vel nulla quisque
          vitae. Scelerisque eget accumsan, non in. Posuere magna erat bibendum
          amet, nisi eu id.
        </p>
        <p class="content-p">
          Et morbi vitae lobortis nam odio. Faucibus vitae vel neque nullam in
          in lorem platea mattis. Euismod aenean rhoncus scelerisque amet
          tincidunt scelerisque aliquam. Luctus porttitor elit vel sapien,
          accumsan et id ut est. Posuere molestie in turpis quam. Scelerisque in
          viverra mi ut quisque. In sollicitudin sapien, vel nulla quisque
          vitae. Scelerisque eget accumsan, non in. Posuere magna erat bibendum
          amet, nisi eu id.
        </p>

        <div class="image">
          <img src="../../../assets/pic1.png" alt="" srcset="" />
        </div>

        <h2>What We Achieved</h2>

        <p class="content-p">
          Posuere sed pulvinar enim faucibus justo, cursus. In diam facilisi at
          sit convallis blandit blandit in. Maecenas odio orci lectus urna ante
          consequat erat non morbi. Rhoncus ullamcorper dictum sit non semper
          sit tellus adipiscing. Est sapien rhoncus sit vestibulum sollicitudin.
          Tellus urna malesuada eu id tempus lorem. Est aliquam sem bibendum
          felis interdum pharetra. Diam fermentum in lectus morbi at eget sit et
          quisque. Semper commodo viverra donec magna egestas nibh. Condimentum
          pellentesque auctor ornare at at tellus nunc cras eu. Velit dignissim
          penatibus faucibus tempus. Arcu pharetra morbi bibendum et dolor
          volutpat amet. Sed mauris amet mi sed purus vitae odio. Mi eu lectus
          suscipit sagittis, ultrices ut dui.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Overview',
};
</script>

<style scoped>
img {
  width: 100%;
  border-radius: 20px;
}

.wrapper {
  padding: 5rem;
}

h1 {
  font-size: 56px;
}
</style>
