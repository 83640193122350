<template>
  <section>
    <div>
      <section>
        <div class="form">
          <div class="input-div">
            <input type="text" v-model="name" placeholder="Event Name" />
          </div>

          <div class="input-div">
            <!-- <textarea
              name=""
              v-model="description"
              id=""
              placeholder="Event description"
              cols="30"
              rows="10"
            ></textarea> -->
            <vue-editor v-model="description"></vue-editor>

          </div>

          <input
            style="display: none"
            type="file"
            @change="uploadFlyer"
            ref="fileInput"
          />

          <div class="button-div">
            <Button
              :text="'Upload Flyer'"
              :color="'#000000'"
              :background="'#F2C94C '"
              @click.native="$refs.fileInput.click()"
            />
          </div>
        </div>

        <div class="flyer-div">
          <h1 class="heading">Flyer</h1>
          <img :src="flyerUrl" alt="flyer" v-show="flyerUrl" />
        </div>
      </section>
      <main id="root" class="mt-5">
        <div class="container">
          <div class="row">
            <vue-transmit
              class="col-12"
              tag="section"
              v-bind="options"
              upload-area-classes="bg-faded"
              ref="uploader"
              :maxFiles="10"
            >
              <div
                class="d-flex upload align-items-center justify-content-center w-100"
                style="height: 50vh; border-radius: 1rem"
              >
                <h3 class="text-centerx">Drop files here</h3>

                <!-- <Button
                  :text="'Upload Files'"
                  :color="'#000000'"
                  :background="'#F2C94C '"
                  @click.native="$refs.fileInput.click()"
                /> -->

                <input
                  style="display: none"
                  type="file"
                  @change="uploadFlyer"
                  ref="fileInput"
                />
              </div>
              <!-- Scoped slot -->
              <template slot="files" scope="props">
                <h1 class="heading">Photos</h1>
                <div class="layout">
                  <div v-for="(file, index) in props.files" :key="file.id">
                    <ImageCard
                      :imageUrl="file.dataUrl"
                      :file="file"
                      :editMode="true"
                      :index="index"
                    />
                  </div>
                </div>
                <div class="button-div">
                  <Button
                    :text="'Submit'"
                    :color="'#000000'"
                    :background="'#F2C94C '"
                    @click.native="handleSubmit"
                  />
                </div>
              </template>
            </vue-transmit>
          </div>
        </div>
      </main>
    </div>

    <Spinner :isLoading="isLoading" />
  </section>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from 'axios';
import { bus } from '../../main';
import ImageCard from '../../components/ImageCard.vue';
import Button from '../../components/Button.vue';
import Spinner from '../../components/Spinner.vue';
export default {
  name: 'AddEvent',
  components: { ImageCard, Button, Spinner, VueEditor},
  data: () => ({
    flyerUrl: '',
    isLoading: false,
    description: '',
    name: '',
    payload: new FormData(),
    options: {
      acceptedFileTypes: ['image/*'],
      url: '',
      clickable: false,
    },

    captions: {},
  }),

  methods: {
    triggerBrowse() {
      this.$refs.uploader.triggerBrowseFiles();
    },

    openNotification(position = null, color) {
      this.$vs.notification({
        color,
        position,
        title: 'Upload Successful!',
      });
    },

    uploadFlyer(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => (this.flyerUrl = reader.result);
      this.payload.set('flyer', event.target.files[0]);
    },

    async handleSubmit() {
      const {
        _data: { files },
      } = this.$refs.uploader;

      this.payload.set('name', this.name);
      this.payload.set('description', this.description);
      this.payload.set('captions', JSON.stringify(this.captions));

      files.forEach((file) => this.payload.append('photo', file._nativeFile));

      await this.sendRequest();

      this.payload = new FormData();
    },

    async sendRequest() {
      this.isLoading = true;
      try {
        await axios.post(
          'https://lti-portfolio-backend.onrender.com/api/v1/events',
          // 'https://ltiportfolio.herokuapp.com/api/v1/events',
          // 'http://127.0.0.1:5000/api/v1/events',
          this.payload
        );

        this.isLoading = false;
        this.openNotification('top-center', 'success');
      } catch (error) {
        console.log(error.response);
        this.isLoading = false;
      }
    },
  },

  filters: {
    json(value) {
      return JSON.stringify(value, null, 2);
    },
  },

  mounted() {
    bus.$on('file-remove', (file) => this.$refs.uploader.removeFile(file));

    bus.$on('add-caption', ({ index, caption }) => {
      this.captions[index] = caption;
    });
  },
};
</script>

<style scoped>
@import '~vue-transmit/dist/vue-transmit.css';

.layout {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  padding: 2rem;
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.form-submit {
  justify-content: center;
  align-items: center;
  display: flex;
}

input,
textarea {
  display: block;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

img {
  width: 100%;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-div {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
</style>
