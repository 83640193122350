<template>
  <div>
    <Sponsors />
    <Contribute />
    <MainFooter />
  </div>
</template>

<script>
import Sponsors from './components/Sponsors.vue';
import Contribute from './components/Contribute.vue';
import MainFooter from './components/MainFooter.vue';
export default {
  name: 'Footer',
  components: { Contribute, MainFooter, Sponsors },
};
</script>
