<template>
  <div>
    <KnowAboutUs />
    <ShowCase />
    <Journey />
    <Team />
  </div>
</template>

<script>
import KnowAboutUs from './sections/KnowAboutUs.vue';
import ShowCase from './sections/ShowCase.vue';
import Journey from './sections/Journey.vue';
import Team from './sections/Team.vue';
export default {
  name: 'AboutPage',
  components: { KnowAboutUs, ShowCase, Journey, Team },
};
</script>
