<template>
  <section>
    <div class="heading-dash">
      <span class="side-dash"></span>
      <h5>What we do</h5>
    </div>

    <div class="flex-layout">
      <div class="content">
        <h1 class="heading">We are working cross country</h1>
        <p class="content-p">
          LTIDF organises interactive meetings, outreaches, seminars educational
          training, Radio/TV advocacy programs and health care interventions
          that will help relieve the pain and challenges of challenged people
          especially children and their parents, guardians and teachers. Our
          Strategies include:-
        </p>

        <ul class="fa-ul">
          <li>
            <span class="fa-li"><i class="fa-solid fa-check-square"></i></span
            >Outreaches into villages and Communities to bring out the
            challenged people and their parents to enjoy the available
            facilities for special children.
          </li>

          <li>
            <span class="fa-li"><i class="fa-solid fa-check-square"></i></span
            >Provision of a platform for a proper medical check-up, diagnosis of
            specific disabilities and prompt intervention.
          </li>

          <li>
            <span class="fa-li"><i class="fa-solid fa-check-square"></i></span
            >Collaboration with government agencies for the introduction of
            policies for improved intervention.
          </li>

          <li>
            <span class="fa-li"><i class="fa-solid fa-check-square"></i></span
            >Early Counselling for new Parents of children with physical,
            mental, sensory and behavioural disability.
          </li>

          <li>
            <span class="fa-li"><i class="fa-solid fa-check-square"></i></span
            >Educational and therapeutic centers for early intervention for the
            alleviation of the effect of disability on the children.
          </li>
        </ul>
      </div>

      <div class="image">
        <img
          src="../../../assets/WhatsApp2.jpeg"
          alt=""
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style scoped>
.image {
  height: 100%;
}

li {
  margin-bottom: 1rem;
  line-height: 1.5rem;
  color: #525560;
}
</style>
