<template>
  <section id="what-we-do">
    <h2>Aspects Of Larger Than I</h2>

    <div class="services grid-layout">
      <div class="service">
        <span><i class="fa fa-graduation-cap" aria-hidden="true"></i></span>
        <div class="service-content">
          <h4>Education</h4>
          <p class="content-p">
            Larger than i is an all inclusive school that afford the children
            living with disabilities to interact with children without
            disabilities
          </p>
        </div>
      </div>

      <div class="service">
        <span><i class="fa fa-leaf"></i></span>
        <div class="service-content">
          <h4>Health</h4>
          <p class="content-p">
            We offer heaalth conselling the parents for parents whose childern
            have disaabilities e.g Speech therapy
          </p>
        </div>
      </div>

      <div class="service">
        <span><i class="fa-solid fa-disease"></i> </span>
        <div class="service-content">
          <h4>Prevention of Disabilities</h4>
          <p class="content-p">
            Lager than i educate parent and the general public on how to prevent
            disabilities in children through their public campaign programs
          </p>
        </div>
      </div>

      <div class="service">
        <span><i class="icon-warning-sign"></i> </span>
        <div class="service-content">
          <h4>General Intervention</h4>
          <p class="content-p">
            We provide therapy or our special needs children. For example
            Applied behavior analysis (ABA) is often referred to as the gold
            standard of autism therapy, largely because therapists set very
            specific, measurable goals and often succeed in teaching skills
          </p>
        </div>
      </div>

      <div class="service">
        <span><i class="fa fa-gavel" aria-hidden="true"></i> </span>
        <div class="service-content">
          <h4>Advocacy</h4>
          <p class="content-p">
            We advocate for childern living with disability through engagement
            with the government and all other agencies that are part of policy
            making
          </p>
        </div>
      </div>

      <div class="service">
        <span><i class="fa fa-bullhorn" aria-hidden="true"></i> </span>
        <div class="service-content">
          <h4>Outreach</h4>
          <p class="content-p">
            we carry out various outreaches to communities to enlighten and
            offer help to those in need
          </p>
        </div>
      </div>

      <!-- second-row -->
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhatWeDo',
};
</script>

<style scoped>
section {
  background: #fcedc6;
}

.service {
  display: flex;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

h4 {
  margin: 0;
  font-size: 24px;
}

.service span {
  font-size: 18px;
  border-radius: 4px;
  color: #fcedc6;
  background: #000;
  height: fit-content;
  padding: 8px;
}

.service-content {
  margin-left: 15px;
}

p {
  margin-top: 15px;
}

@media (max-width: 600px) {
  .grid-layout {
    display: flex;
    flex-direction: column;
  }
}
</style>
